/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/19
* @description
*   BindUPIAccount.vue of WeTrade
*/
<template>
  <DialogTemplate>
    <template #title><t path="withdrawal_15">Add UPI account</t></template>
    <div class="flex-1">
      <p class="c-title f-md mt-32"><t path="withdrawal_16" :args="{name}">Payee's name: {{name}}</t></p>
      <div class="mt-8 mb-24">
        <div class="form-item block">
          <t path="withdrawal_17" custom #="{td}">
            <input class="input" type="text" v-model="account" :placeholder="td || 'UPI Account'">
          </t>
        </div>
      </div>
    </div>
    <template #confirm>
      <Button class="f-bold f-lg primary flex-1" @click="submit" :progress="progress">
        <t path="withdrawal_19" #="{td}" custom>{{ td || 'Submit' }}</t>
      </Button>
    </template>
  </DialogTemplate>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import useRequest from '@/hooks/useRequest'
import { bindFA, FAAccountType } from '@/modules/out/out.api'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'BindUPIAccount',
  components: { Button, DialogTemplate },
  props: {
    name: String,
  },
  setup (props, ctx) {
    const account = shallowRef('')
    const [commit, progress] = useRequest(bindFA)

    return {
      account,
      submit () {
        commit({
          type: FAAccountType.UPI,
          vpa: account.value,
        }).then(() => {
          ctx.emit('confirm')
          ctx.emit('close')
        })
      },
      progress,
    }
  },
})
</script>
